import React, { Component } from "react";
import { 
  Box, 
  Grid, 
  Paper, 
  Typography, 
  List, 
  ListItem, 
  ListItemText,
  ListItemIcon, 
  Divider 
} from "@material-ui/core";
import { Line } from "@reactchartjs/react-chart.js";

import moment from "moment";
import { chain } from 'lodash';

import { 
  fetchClipsbyDateRange,
  getLatestNewsClips,
  getPubByMentions,
  getJournalistsByMentions
} from "../../utils/helper";

import { AuthContext } from "../../AuthProvider";
import { ROLES } from "../../contants/auth";


const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const lineChartData = {
  labels: [ ...MONTHS ],
  datasets: [
    {
      data: [40, 50, 20, 30, 60, 70, 10, 49, 30, 40, 100, 90],
      fill: false,
      label: "News clips uploaded",
      backgroundColor: "rgb(71, 89, 228)",
      borderColor: "rgba(71, 89, 228, 0.2)",
    },
  ],
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
};

const processStats = (snapshot) =>{

  let processedData = [];

  snapshot.forEach(child => {
    const newsClip = child.val();
    if(!newsClip.isActive) return;
    processedData.push(newsClip);
  });

  processedData.reverse();

  return processedData;
}

class Dashboard extends Component{
  
  static contextType = AuthContext;

  state = {
    lineData:  [],
    latestClips: [],
    topPublications: [],
    topJournalists: []
  }

  componentDidMount(){
    this.fetchNewsClipStats();
    this.fetchLatestNewClips();
    this.fetchPubByMentions();
    this.fetchJournalistsByMentions();
  }

  fetchNewsClipStats = () =>{
    const { currentUser } = this.context;

    const currentYear = moment().format('YYYY');
    const nextYear = parseInt(currentYear) + 1;

    const startDate = moment(`01/01/${currentYear}`, "DD/MM/YYYY").valueOf();
    const endDate = moment(`01/01/${nextYear}`, "DD/MM/YYYY").valueOf();

    fetchClipsbyDateRange(startDate, endDate)
      .then(snapshot => {
        const newsClips = snapshot.val() || {};

        let updatedData = [];

        if(currentUser.role == ROLES.client){
          
          updatedData = Object.keys(newsClips)
            .filter(id => newsClips[id].clientId == currentUser.uid && newsClips[id].isActive)
            .map(id => ({ date: moment(newsClips[id].createdAt).format('MMM') }));
        
        } else {
          
          updatedData = Object.keys(newsClips)
          .filter(id => newsClips[id].isActive)
          .map(id => ({ date: moment(newsClips[id].createdAt).format('MMM') }));
        }

        updatedData = chain(updatedData)
          .sortBy([ (data) => (MONTHS.indexOf(data.date)) ], ['asc'] )
          .countBy('date')
          .value();

        const lineData = MONTHS.map(month => updatedData[month] || 0 );

        this.setState({ lineData });
      });
  }

  fetchLatestNewClips = () =>{
    getLatestNewsClips()
      .then((snapshot) => {
        const latestClips = processStats(snapshot);
        this.setState({ latestClips });
      });
  }

  fetchPubByMentions = () =>{
    getPubByMentions()
      .then((snapshot) => {
        const topPublications = processStats(snapshot);
        this.setState({ topPublications });
      });
  }

  fetchJournalistsByMentions = () =>{
    getJournalistsByMentions()
      .then((snapshot) => {
        const topJournalists = processStats(snapshot);
        this.setState({ topJournalists });
      });
  }

  render(){ 
    const { 
      lineData,
      latestClips,
      topPublications,
      topJournalists
    } = this.state;

    const { currentUser } = this.context;
    
    lineChartData.datasets[0].data = [ ...lineData ];

    return (
      <>
        <Box m={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Paper elevation={0} style={{ padding: 20 }}>

                <Line data={lineChartData} options={options} height={80} />

              </Paper>
            </Grid>

            {currentUser.role !== ROLES.client && (
              <>
                {/* -------------LATEST NEW CLIPS------------------ */}
                <Grid item sm={6}>
                  <Paper elevation={0} style={{ padding: 20 }}>
                    <Typography variant="h6">Latest news clips</Typography>
                    <List dense={false}>
                      {latestClips.slice(0,5).map((clip, index) =>(
                        <React.Fragment key={`clip_${index}`}>
                          <ListItem>

                            <ListItemIcon><h2>{index + 1}</h2></ListItemIcon>

                            <ListItemText
                              primary={clip.headline}
                              secondary={null}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                </Grid>

                {/* -------------TOP PUBLICATIONS BY MENTIONS------------------ */}
                <Grid item sm={6}>
                  <Paper elevation={0} style={{ padding: 20 }}>
                    <Typography variant="h6">
                      Top publication type by mentions
                    </Typography>
                    <List dense={false}>
                      {topPublications.slice(0,5).map((publication, index) =>(
                        <React.Fragment key={`clip_${index}`}>
                          <ListItem>

                            <ListItemText
                              primary={publication.name}
                            />

                            <ListItemText
                              style={{ textAlign: 'right'}}
                              primary={`${publication.mentionsCount} MENT`}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                </Grid>

                {/* -------------TOP JOURNALIST BY MENTIONS------------------ */}
                <Grid item sm={6}>
                  <Paper elevation={0} style={{ padding: 20 }}>
                    <Typography variant="h6">Top journalists by mentions</Typography>
                    <List dense={false}>
                      {topJournalists.slice(0,5).map((journalist, index) =>(
                        <React.Fragment key={`clip_${index}`}>
                          <ListItem>

                            <ListItemText
                              primary={journalist.name}
                            />

                            <ListItemText
                              style={{ textAlign: 'right'}}
                              primary={`${journalist.mentionsCount} MENT`}
                            />
                          </ListItem>
                          <Divider />
                        </React.Fragment>
                      ))}
                    </List>
                  </Paper>
                </Grid>

                {/* -------------TOP CLIENTS BY MENTIONS------------------ */}
                <Grid item sm={6}>
                  {/* <Paper elevation={0} style={{ padding: 20 }}>
                    <Typography variant="h6">Top clients by mentions</Typography>
                  </Paper> */}
                </Grid>
              </>
            )}
            
          </Grid>
        </Box>
      </>
    )
  }
};

export default Dashboard;
