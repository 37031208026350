import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import uploadImageHandler from '../../utils/upload';
import HelperTooltip from './HelperTooltip';

const styles = theme => ({
    dndContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        border: '2px dashed #bdbdbd',
        width: '100%',
        height: '70px',
        cursor: 'pointer',
        marginBottom: "5px",
    },
    progressBar: {
        width: "70%",
        marginTop: "10px",
    },
    imgList: {
        display: "flex",
        border: "1px solid #bdbdbd",
        padding: "10px",
        borderRadius: "5px",
    },
    imgContainer: {
        width: "100px",
        height: "100px",
        marginRight: "20px",
        borderRadius: "3px",
        overflow: "hidden",
        '& img':{
            width: '100%',
            height: '100%',
            objectFit: "cover",
        }
    }
});

const convertToSqrCms = (dimensions) =>{
    const conversionFactor = 0.0264583333;
    const widthInCms = dimensions.width * conversionFactor;
    const heightInCms = dimensions.height * conversionFactor;
    const sqrCms = widthInCms * heightInCms;
    return sqrCms;
}

class UploadInput extends Component {
    
    state = {
        selectedImageUrl: '',
        progressVal: 0
    }

    componentDidMount() {
        let element = this.dropRef;
        element.addEventListener('dragover', this.handleDrag);
        element.addEventListener('drop', this.handleDrop);
        this.setState({ selectedImageUrl: this.props.imageUrl });
    }

    handleDrag = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        this.setState({ drag: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.uploadHandler(e.dataTransfer.files);
            e.dataTransfer.clearData();
            this.dragCounter = 0;
        }
    }

    componentWillUnmount() {
        let element = this.dropRef;
        element.removeEventListener('dragover', this.handleDrag);
        element.removeEventListener('drop', this.handleDrop);
    }

    handleFileUpload = event => {
        const files = event.target.files;
        this.uploadHandler(files);
    }

    uploadHandler = (files) =>{
        const cbHandlers = {
            getUploadProgress: this.getUploadProgress,
            successHandler: this.successHandler,
            errorHandler: this.errorHandler,
        }
        uploadImageHandler(files[0], cbHandlers);
    }

    getUploadProgress = (progressVal, status) =>{
        this.setState({ progressVal });
    }

    errorHandler = (error) =>{
        this.setState({ progressVal: 0 });
        console.log('error ',error);
    }

    successHandler = async (imageUrl, filename) =>{
        const { handleUploadSuccess, imageIndex } = this.props;

        const imageDimensions = await this.imageSize(imageUrl);
        this.setState({
            progressVal: 0,
            selectedImageUrl: imageUrl
        });

        //Converting image dimensions to square centimeters;
        const imgSqrCms = convertToSqrCms(imageDimensions);

        const imageData = {
            url: imageUrl,
            sqrCms: imgSqrCms,
            filename
        }

        handleUploadSuccess(imageIndex, imageData);
    }

    imageSize = url =>{
        const image = new Image();
        const promise = new Promise((resolve, reject) =>{
            image.onload = () =>{
                const width = image.naturalWidth;
                const height = image.naturalHeight;
                resolve({ width, height });
            }

            image.onerror = reject;
        });

        //load image src.............
        image.src = url;
        return promise;
    }

    render() {
        const { classes, imageIndex } = this.props;
        const { progressVal, selectedImageUrl } = this.state;
        return (
            <Grid item sm={12}>

                <label htmlFor={`upload-new-id_${imageIndex}`}>
                    <section
                        ref={el => this.dropRef = el}
                        className={classes.dndContainer}
                    >
                        <p>Drag and drop image here, or click to select image</p>

                        {progressVal > 0 && (
                            <div className={classes.progressBar}>
                                <LinearProgress variant="determinate" value={progressVal} />
                            </div>
                        )}

                    </section>
                </label>

                <input
                    type="file"
                    id={`upload-new-id_${imageIndex}`}
                    style={{ display: "none" }}
                    onChange={this.handleFileUpload}
                    accept="image/*"
                />

                {/* --------------IMAGE DISPLAY------------------ */}
                {selectedImageUrl && (
                    <div className={classes.imgList}>
                        <div className={classes.imgContainer}>
                            <img src={selectedImageUrl} />
                        </div>
                    </div>
                )}

                <HelperTooltip />
            </Grid>
        );
    }
}
export default withStyles(styles)(UploadInput);