import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import firebase, { database } from '../../firebaseConfig';
import cloneDeep from 'lodash/cloneDeep';

import { AuthContext } from "../../AuthProvider";

import MaterialTable from 'material-table';
import AddJournalistsDialog from './AddJournalistsDialog';

import { getPublications, getJournalists } from "../../utils/helper";

const styles = theme =>({
    root: {
        margin: '0px 20px',
        '& .MuiToolbar-root > div:nth-of-type(4)': {
            order: -1
        }
    }
});

class Journalists extends Component {

    static contextType = AuthContext;

    state = {
        showModal: false,
        journalists: [],
        selectedJournalist: null,
        selectedID: null,
        publications: []
    }

    componentDidMount(){
        this.fetchPublications();
        this.fetchJournalists();
    }

    fetchPublications = () =>{
        getPublications()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const publications = Object.keys(datalist).map(id => ({ name: datalist[id].name, id }));
                    this.setState({ publications });
                }
            });
    }

    fetchJournalists = () =>{
        getJournalists()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const journalists = Object.keys(datalist).map(id => ({ ...datalist[id], id }) );
                    this.setState({ journalists });
                }
            });
    }

    addJournalistHandler = (form) =>{
        const { currentUser } = this.context;

        const journaliststRef = database.ref('journalists');
        const newJournalistssRef = journaliststRef.push();

        newJournalistssRef.set({
            name: form.name,
            email: form.email,
            phone: form.phone,
            category: form.category,
            publicationId: form.publicationId,
            mentionsCount: 0,
            createdAt: firebase.database.ServerValue.TIMESTAMP,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            createdBy: currentUser.uid,
            updatedBy:  currentUser.uid,
            isActive: true
        });

        this.closeModalHandler();
    }

    updateJournalistHandler = (form) =>{

        const { selectedID } = this.state;
        const { currentUser } = this.context;

        const journaliststRef = database.ref('journalists/' + selectedID);
        journaliststRef.update({ 
            ...form,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            updatedBy:  currentUser.uid
        });
        this.closeModalHandler();
    }

    deleteJournalistHandler = (id) =>{
        const { currentUser } = this.context;
        
        const journaliststRef = database.ref('journalists/' + id);
        journaliststRef.update({ 
            isActive: false,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            updatedBy:  currentUser.uid  
        });
    }

    closeModalHandler = () =>{
        this.setState({ 
            showModal: false, 
            selectedID: null,
            selectedJournalist: null
        });
    }

    editHandler = (event, rowData) =>{
        const allowedKeys = [
            'name',
            'email',
            'phone',
            'category',
            'publicationId',
        ];
        const filteredData = Object.keys(rowData)
            .filter(key => allowedKeys.includes(key))
            .reduce((res, key) =>Object.assign(res, { [key]: rowData[key]}), {});

        this.setState({
            selectedJournalist: { ...filteredData },
            showModal: true,
            selectedID: rowData.id
        });
    }

    deleteHandler = (event, rowData) =>{
        const status = window.confirm("You want to delete " + rowData.type);
        if(status) this.deleteJournalistHandler(rowData.id);
    }

    render(){
        const { classes } = this.props;
        const { 
            showModal, 
            journalists, 
            selectedJournalist, 
            selectedID, 
            publications
        } = this.state;

        const tableData = cloneDeep(journalists);

        return(
            <div className={classes.root}>
                <MaterialTable
                    title="Journalists"
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Email', field: 'email' },
                        { title: 'Phone', field: 'phone'},
                        { title: 'Category', field: 'category'},
                        { 
                            title: 'Publication', 
                            field: 'publicationId', 
                            render: rowData =>  publications.find(item => item.id == rowData.publicationId)?.name
                        },
                    ]}
                    data={tableData}        
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add Journalist',
                            isFreeAction: true,
                            onClick: (event) => this.setState({ showModal: true })
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Update Journalist',
                            onClick: this.editHandler
                        },
                        rowData => ({
                            icon: 'delete',
                            tooltip: 'Delete Journalist',
                            onClick: this.deleteHandler,
                            disabled: rowData.birthYear < 2000
                        })
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: []
                    }}
                />

                {showModal && (
                    <AddJournalistsDialog 
                        open={showModal}
                        addJournalistHandler={this.addJournalistHandler}
                        updateJournalistHandler={this.updateJournalistHandler}
                        handleClose={this.closeModalHandler}
                        selectedJournalist={selectedJournalist}
                        selectedID={selectedID}
                        publications={publications}
                    />
                )}
            </div>
        )
    }
}

export default withStyles(styles)(Journalists);

