import firebase, { storage } from '../../firebaseConfig';

const uploadImageHandler = (file, { getUploadProgress, errorHandler, successHandler }) => {
    // Create the file metadata
    // const metadata = {
    //     contentType: 'image/jpeg'
    // };

    const storageRef = storage.ref();

    // Upload file and metadata to the object `images/${file.name}`
    const timeStamp = new Date().getTime();
    const uploadTask = storageRef.child('images/' + file.name + `_${timeStamp}`).put(file);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            let status = '';
            
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: 
                    status = 'paused';
                    break;
                case firebase.storage.TaskState.RUNNING:
                    status = 'inProgress';
                    break;
            }

            getUploadProgress(progress, status);
        },
        (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    errorHandler('unauthorized');
                    break;
                case 'storage/canceled':
                    // User canceled the upload
                    errorHandler('cancelled');
                    break;

                // ...

                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    errorHandler('server error');
                    break;
            }
        },
        () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                successHandler(downloadURL, file.name);
            });
        }
    );
}

export default uploadImageHandler;

