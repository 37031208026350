import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { ROLES } from '../../contants/auth';

import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    FormHelperText
} from "@material-ui/core";

const initialValues = {
    username: '',
    email: '',
    password: '',
    role: ''
};

const USER_ROLES = [
    { label: 'Admin', value: ROLES.admin },
    { label: 'Client', value: ROLES.client },
    { label: 'Super admin', value: ROLES.super_admin },
];

const AddUserDialog = (props) => {

    const { 
        open, 
        addUserHandler,
        handleClose,
        selectedID,
        selectedUser,
        updateUserHandler
    } = props;

    useEffect(() =>{
        if(selectedID) setValues({ ...selectedUser });
    }, [selectedID]);
    
    const {
        touched,
        errors,
        getFieldProps,
        setValues,
        handleSubmit
    } = useFormik({

        initialValues: { ...initialValues },

        validationSchema: Yup.object().shape({
            username: Yup.string().required("Required"),
            email: Yup.string().required("Required"),
            role: Yup.string().required("Required"),
            ...(!selectedID && { password: Yup.string().required("Required") }),
        }),

        onSubmit: values => {
            
            if(selectedID){
                updateUserHandler(values);
                return;
            }

            addUserHandler(values);
        }
    });

    const getError= (field) =>{
        const errorStatus = {
            status: false,
            msg: ''
        }

        const hasError = touched[field] && errors[field] ? true : false;

        if(hasError){
            errorStatus.status = true;
            errorStatus.msg = errors[field];
        }

        return errorStatus;
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
        >

            <DialogTitle id="form-dialog-title">Add User</DialogTitle>

            <DialogContent>
                <TextField
                    error={getError("username").status}
                    helperText={getError("username").msg}
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="username"
                    {...getFieldProps("username")}
                />

                <TextField
                    error={getError("email").status}
                    helperText={getError("email").msg}
                    margin="dense"
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    id="email"
                    {...getFieldProps("email")}
                />

                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="users">User type</InputLabel>
                    <Select
                        error={getError("role").status}
                        labelId="users"
                        label="User type"
                        id="role"
                        {...getFieldProps("role")}
                    >  
                        {USER_ROLES.map(role =>(
                            <MenuItem 
                                key={`role_${role.value}`} 
                                value={role.value}
                            >
                                {role.label}
                            </MenuItem>
                        ))}
                    </Select>

                    <FormHelperText>{getError("role").msg}</FormHelperText>
                </FormControl>

                <TextField
                    error={getError("password").status}
                    helperText={getError("password").msg}
                    margin="dense"
                    label="Password"
                    type="password"
                    variant="outlined"
                    fullWidth
                    id="password"
                    {...getFieldProps("password")}
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>

                <Button onClick={handleSubmit} color="primary">
                    {selectedID ? 'Update' : 'Add'}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddUserDialog.propTypes = {
    // addUserHandler: PropTypes.func.isRequired,
};

export default AddUserDialog;
