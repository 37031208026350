import React from 'react';
import DashboardIcon from "@material-ui/icons/Dashboard";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import PersonIcon from "@material-ui/icons/Person";
import TelegramIcon from "@material-ui/icons/Telegram";
import RecordVoiceOverIcon from "@material-ui/icons/RecordVoiceOver";

import { SIDEBAR_MENU as MENU } from '../../../contants/sidebar';

const NavItemIcon = props =>{
    const { itemType, ...iconProps } = props;
    return({
        
        [MENU.dashboard.id]: <DashboardIcon { ...iconProps }/>,
        [MENU.newClips.id]: <MenuBookIcon { ...iconProps }/>,
        [MENU.users.id]: <PersonIcon { ...iconProps }/>,
        [MENU.publications.id]: <TelegramIcon { ...iconProps }/>,
        [MENU.journalists.id]: <RecordVoiceOverIcon { ...iconProps }/>,

    })?.[itemType] || null;
}

export default NavItemIcon;