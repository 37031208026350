import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

import { 
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    TextField,
    FormHelperText
} from "@material-ui/core";

const initialValues = {
    name: '',
    type: '',
    zone: '',
    assignPublication: '',
    language: '',
    edition: '',
    adValue: '',
    circulationValue: ''
};

const AddPublicationDialog = (props) => {

    const { 
        addPublicationHandler, 
        updatePublicationHandler,
        open, 
        handleClose,
        selectedPublication,
        selectedID, 
    } = props;

    useEffect(() =>{
        if(selectedID) setValues({ ...selectedPublication });
    }, [selectedID]);
    
    const {
        touched,
        errors,
        getFieldProps,
        setValues,
        handleSubmit
    } = useFormik({

        initialValues: { ...initialValues, ...selectedPublication },

        validationSchema: Yup.object().shape({
            name: Yup.string().required("Required"),
            type: Yup.string().required("Required"),
            zone: Yup.string().required("Required"),
            assignPublication: Yup.string().required("Required"),
            language: Yup.string().required("Required"),
            edition: Yup.string().required("Required"),
            adValue: Yup.string().required("Required"),
            circulationValue: Yup.string().required("Required")
        }),

        onSubmit: values => {
            
            if(selectedID){
                updatePublicationHandler(values);
                return;
            }

            addPublicationHandler(values);
        }
    });

    const getError= (field) =>{
        const errorStatus = {
            status: false,
            msg: ''
        }

        const hasError = touched[field] && errors[field] ? true : false;

        if(hasError){
            errorStatus.status = true;
            errorStatus.msg = errors[field];
        }

        return errorStatus;
    }

    return (
        
            <Dialog
                open={open}
                aria-labelledby="form-dialog-title"
            >

                <DialogTitle id="form-dialog-title">Add Publication</DialogTitle>

                <DialogContent>

                    {/* -------------TYPE INPUT---------------- */}
                    <TextField
                        error={getError("name").status}
                        helperText={getError("name").msg}
                        margin="dense"
                        label="Name"
                        type="text"
                        variant="outlined"
                        fullWidth
                        autoFocus
                        id="name"
                        {...getFieldProps("name")}
                    />

                    {/* -------------TYPE INPUT---------------- */}
                    <TextField
                        error={getError("type").status}
                        helperText={getError("type").msg}
                        margin="dense"
                        label="Type"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id="type"
                        {...getFieldProps("type")}
                    />

                    {/* -------------ZONE INPUT---------------- */}
                    <FormControl 
                        variant="outlined" 
                        fullWidth
                        error={getError("zone").status}
                    >
                        <InputLabel id="users">Zone</InputLabel>
                        <Select
                            error={getError("zone").status}
                            labelId="zone"
                            label="Zone"
                            id="zone"
                            {...getFieldProps("zone")}
                        >
                            <MenuItem value={"North"}>North</MenuItem>
                            <MenuItem value={"South"}>South</MenuItem>
                            <MenuItem value={"East"}>East</MenuItem>
                            <MenuItem value={"West"}>West</MenuItem>
                        </Select>

                        <FormHelperText>{getError("zone").msg}</FormHelperText>

                    </FormControl>

                    {/* -------------ASSIGN PUBLICATION INPUT---------------- */}
                    <FormControl 
                        variant="outlined" 
                        fullWidth
                        error={getError("assignPublication").status}
                    >
                        <InputLabel id="users">Assign Publication</InputLabel>
                        <Select
                            labelId="assignpublication"
                            label="Assign Publication"
                            id="assignPublication"
                            {...getFieldProps("assignPublication")}
                        >
                            <MenuItem value={"Mainline"}>Mainline</MenuItem>
                            <MenuItem value={"Regional"}>Regional</MenuItem>
                            <MenuItem value={"Magazine"}>Magazine</MenuItem>
                        </Select>
                        
                        <FormHelperText>{getError("assignPublication").msg}</FormHelperText>

                    </FormControl>

                    {/* -------------LANGUAGE INPUT---------------- */}
                    <TextField
                        error={getError("language").status}
                        helperText={getError("language").msg}
                        margin="dense"
                        label="Language"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id="language"
                        {...getFieldProps("language")}
                    />

                    {/* -------------EDITION INPUT---------------- */}
                    <TextField
                        error={getError("edition").status}
                        helperText={getError("edition").msg}
                        margin="dense"
                        label="Edition"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id="edition"
                        {...getFieldProps("edition")}
                    />

                    {/* -------------PR VALUE INPUT---------------- */}
                    <TextField
                        error={getError("adValue").status}
                        helperText={getError("adValue").msg}
                        margin="dense"
                        label="Ad Value"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id="adValue"
                        {...getFieldProps("adValue")}
                    />

                    {/* -------------CIRCULATION VALUE INPUT---------------- */}
                    <TextField
                        error={getError("circulationValue").status}
                        helperText={getError("circulationValue").msg}
                        margin="dense"
                        label="Circulation Value"
                        type="text"
                        variant="outlined"
                        fullWidth
                        id="circulationValue"
                        {...getFieldProps("circulationValue")}
                    />
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>

                    <Button onClick={handleSubmit} color="primary">
                        {selectedID ? 'Update' : 'Add' }
                    </Button>
                </DialogActions>

            </Dialog>
    );
};

AddPublicationDialog.propTypes = {
    open: PropTypes.bool, 
    handleClose: PropTypes.func.isRequired,
    addPublicationHandler: PropTypes.func.isRequired
};

export default AddPublicationDialog;
