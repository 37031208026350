import React, { useEffect, useState } from "react";
import { auth } from "./firebaseConfig";
import { getUserData, getCurrentUserInfo } from './utils/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from "@material-ui/core/styles";


export const AuthContext = React.createContext();

const useStyles = makeStyles(theme =>({
    loader: {
        top: "0px",
        left: "0px",
        right: "0px",
        height: "100%",
        display: "flex",
        position: "fixed",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 99,
        background: "#ffffff3d",
    }
}))

export const AuthProvider = ({ children }) => {
    
    const [currentUser, setCurrentUser] = useState(null);
    const [pending, setPending] = useState(true);
    const [loading, setLoading] = useState(false)
    const classes = useStyles();

    useEffect(() => {
        auth.onAuthStateChanged(async(user) => {
            let userData = user || null;

            if(userData){
                //GET USERS INFO FROM DATABASE.........
                // userData = await getUserData(userData.uid);
                userData = await getCurrentUserInfo();
                setCurrentUser({ 
                    uid: user.uid,
                    email: userData.email,
                    role: userData.role,
                    username: userData.name 
                });
            }
            
            setPending(false);
        });
    }, []);

    const showLoader = () => setLoading(true);
    const hideLoader = () => setLoading(false);

    if (pending) {
        return <>Loading...</>
    }

    return (
        <>
            <AuthContext.Provider
                value={{
                    currentUser,
                    showLoader,
                    hideLoader
                }}
            >
                {children}
            </AuthContext.Provider>

            {/* -------------GLOBAL LOADER---------------- */}
            {loading && (
                <div className={classes.loader}>
                    <CircularProgress />
                </div>
            )}
        </>
    );
};