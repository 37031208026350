import React, { useContext } from "react";
import styled from "styled-components";
import { Badge, Avatar, IconButton, Popover } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import { auth } from '../../firebaseConfig';
import { AuthContext } from "../../AuthProvider";

const UserWrapper = styled.div`
  color: var(--color-white);
  height: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
  padding: 2rem 0;
`;

const StyledBadge = styled(Badge)`
  & .MuiBadge-dot {
    background-color: #44b700;
  }
`;
const StyledAvatar = styled(Avatar)`
  font-size: 1.8rem !important;
  font-weight: 400 !important;
`;

const UserDetails = styled.div`
  flex: 1 1 0px;
  margin-left: 1rem;

  & p {
    margin-bottom: 0;
    line-height: 1.2;

    &:nth-child(1) {
      font-weight: 400;
      color: var(--color-textGrey);
      font-size: var(--font-small);
    }
    ,
    &:nth-child(2) {
      font-size: var(--font-normal);
    }
  }

  & button {
    padding: 0;
  }

  & .dropdown {
    color: var(--color-white);
  }
`;

const LogoutBtn = styled.button`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  font-weight: 700;
  text-align: left;
  background-color: #fafafa !important;
  color: #4759e4;

  & svg {
    margin-right: 1rem;
  }
`;

const getInitials = name =>{

  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu');

  let initials = [...name.matchAll(rgx)] || [];

  initials = (
    (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
  ).toUpperCase();

  return initials;
}

const User = () => {
  const {currentUser} = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "popover" : undefined;
  const userName = currentUser?.username || 'User';

  const logoutHandler = () =>{
    auth.signOut();
    window.location.reload();
  }

  return (
    <>
      <UserWrapper>
        <StyledBadge
          overlap="circle"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          variant="dot"
        >
          <StyledAvatar alt="Remy Sharp" src="">
            {getInitials(userName)}
          </StyledAvatar>
        </StyledBadge>

        <UserDetails>
          <p>Welcome</p>
          <p>
            {userName}
            <IconButton aria-describedby={id} onClick={handleClick}>
              <ArrowDropDownIcon className="dropdown" />
            </IconButton>
          </p>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <LogoutBtn onClick={logoutHandler}>
              <ExitToAppIcon fontSize="large" /> Logout
            </LogoutBtn>
          </Popover>
        </UserDetails>
      </UserWrapper>
    </>
  );
};

export default User;
