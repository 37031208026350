import React, { useContext } from "react";
import styled from "styled-components";
import NavItem from "./NavItem";

import NavItemIcon from './NavItemIcon';
import { SIDEBAR_MENU } from '../../../contants/sidebar';
import { ACCESS_DENIE_LIST } from '../../../contants/auth';
import { AuthContext } from "../../../AuthProvider";

const Nav = styled.nav`
  display: flex;
  margin-top: ${(props) => (props.mobile ? "-6rem" : null)};
`;

const Ul = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
`;

const NavItems = ({ mobile, clicked }) => {

  const { currentUser } = useContext(AuthContext);

  return (
    <Nav mobile={mobile}>
      <Ul mobile={mobile}>

        {Object.keys(SIDEBAR_MENU).map((menuID, index) =>{
          
          const menu = SIDEBAR_MENU[menuID];

          if(ACCESS_DENIE_LIST?.[currentUser.role]?.includes(menu.link)) return null;

          return (
            <NavItem 
              key={`menu_item${index}`}
              mobile={mobile} 
              clicked={clicked} 
              link={menu.link}
            >
              <NavItemIcon itemType={menu.id} fontSize="large" /> {menu.title}
            </NavItem>
          )
        })}

      </Ul>
    </Nav>
  );
};

export default NavItems;
