
//file format: keyword_position_pageNo_hasPhoto_photoType (eg: nature_tl_5_n_bw)

export const EXAMPLE_FORMAT = 'Filename format: keyword_position_pageNo_hasPhoto_photoType ( eg: nature_tl_5_n_bw )';

export const POSITION_FORMAT = {
    tl: 'top-left',
    tr: 'top-right',
    tc: 'top-center',
    cl: 'center-left',
    c: 'center',
    cr: 'center-right',
    bl: 'bottom-left',
    br: 'bottom-right',
    bc: 'bottom-center',
}