import React, { useState, useEffect, useMemo } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import PropTypes from "prop-types";

import Tooltip from "@material-ui/core/Tooltip";
import { Grid } from "@material-ui/core";

import UploadImageForm from './UploadImageForm';
import NewsClipForm from "./NewsClipForm";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  //
  //
  thumbsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
  },
  thumb: {
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    padding: 4,
    boxSizing: "border-box",
  },
  thumbInner: {
    display: "flex",
    minWidth: 0,
    overflow: "hidden",
  },
  img: {
    display: "block",
    width: "auto",
    height: "100%",
  },

  activeStyle: {
    borderColor: "#2196f3",
  },
  acceptStyle: {
    borderColor: "#00e676",
  },
  rejectStyle: {
    borderColor: "#ff1744",
  },

  dialogContent: {
    padding: 15,
    paddingBottom: 0
  }
}));

function getSteps() {
  return ["Upload news clip image", "Create news clip"];
}

const initImageInfo = {
  url: '',
  sqrCms: '',
  position: '',
  pageNumber: '',
  hasPhoto: '',
  photoType: '',
  keywords: []
}

const initNewsClipInfo = {
  headline: '',
  publicationId: '',
  publishDate: new Date(),
  journalistId: '',
  storyFileFrom: '',
  supplement: '',
  clientId: '',
  magazineDate: new Date()
}

const AddUserDialog = (props) => {
  const { 
    open,
    addNewClipHandler,
    updateNewsClipHandler,
    handleClose,
    selectedNewsClip,
    selectedID,
    publications,
    journalists,
    clients
  } = props;

  const classes = useStyles();
  const steps = getSteps();
  const uploadFormRef = React.useRef(null);
  const createFormRef = React.useRef(null);

  const [activeStep, setActiveStep] = useState(0);
  const [imageList, setImageList] = useState([{ ...initImageInfo }]);
  const [newsClipFormData, setNewsClipFormData] = useState({ ...initNewsClipInfo });

  useEffect(() =>{

    if(selectedID){
      const { images, ...newsClipData } = selectedNewsClip;
      setImageList([ ...images ]);
      setNewsClipFormData({ ...newsClipData });
    }

  }, [selectedID]);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () =>{
    const activeFormRef = activeStep == 0 ? uploadFormRef : createFormRef;
    activeFormRef.current.click()
  }

  const submitImageHandler = data =>{
    setImageList([ ...data ]);
    nextStep();
  }

  const submitNewsClipHandler = data =>{
    const formData = {
      ...data,
      images: [ ...imageList ]
    }

    if(selectedID){
      updateNewsClipHandler(formData);
      return;
    }
    
    addNewClipHandler(formData);
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title">Add News Clip</DialogTitle>

      <DialogContent> 

        {/* ------------------STEPPER COMPONENT--------------------- */}
        <Stepper activeStep={activeStep}>
          {steps.map((label) =>(
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        {/* ------------------STEPPER CONTENT--------------------- */}
        <Grid container spacing={2}>

          {/* ------------------UPLOAD NEWS CLIP IMAGE------------ */}
          {activeStep === 0 && (
            <UploadImageForm
              ref={uploadFormRef}
              imageList={imageList}
              initImageInfo={initImageInfo}
              submitHandler={submitImageHandler}
            />
          )}

          {/* ------------------CREATE NEWS CLIPS------------------ */}
          {activeStep === 1 && (
            <NewsClipForm 
              ref={createFormRef}
              newsClipFormData={newsClipFormData}
              publications={publications}
              journalists={journalists}
              clients={clients}
              submitHandler={submitNewsClipHandler}
            />
          )}

        </Grid>
      </DialogContent>

      <DialogActions>

        {/* ---------------------CANCEL BUTTON----------------------- */}
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        
        {/* ---------------------BACK BUTTON----------------------- */}
        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={classes.button}
        >
          Back
        </Button>
        
        {/* ---------------------NEXT/FINISH BUTTON----------------------- */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleNext}
          className={classes.button}
        >
          {activeStep == 0 ? "Next" : "Finish"}
        </Button>

      </DialogActions>
    </Dialog>
  )
};

// AddUserDialog.propTypes = {
//   addUserHandler: PropTypes.func.isRequired,
// };

export default AddUserDialog;
