import React from 'react';
import styled from 'styled-components';

import Navbar from '../components/Navigation/Navbar';
import SideDrawer from '../components/Navigation/SideDrawer';
import Header from '../components/Header';

const LayoutWrapper = styled.div`
  width: 100%;
  display: flex;
  min-height: 100vh;

  @media ${props => props.theme.mediaQueries.small} {
    flex-direction: column;
  }
`;

const MainWrapper = styled.main`
  flex: 1 1 0px;
  background-color: var(--color-mainLight);
  margin-left: auto;
  padding-top: 40px;
  height: 100vh;
  overflow-y: auto;

  @media ${props => props.theme.mediaQueries.small} {
    margin-left: 0;
  }
`;

const Layout = ({ children }) => (
  <>
    <LayoutWrapper>
      <SideDrawer />
      <Navbar />
      <MainWrapper>
        {/* <Header /> */}
        {children}
      </MainWrapper>
    </LayoutWrapper>
  </>
);

export default Layout;