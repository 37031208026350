import React, { useState, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import {
    Grid,
    Card,
    CardActions,
    CardContent,
    Button,
    TextField,
    InputAdornment,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import { Send, Mail, Lock } from "@material-ui/icons";

import { auth } from '../../firebaseConfig'
import { AuthContext } from "../../AuthProvider";

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "100vh",
        background: "#3f51b5",
    },
    card: {
        minWidth: 275,
    },
    extendedIcon: {
        marginRight: theme.spacing.unit,
    },
    action: {
        flexDirection: "row-reverse",
    },
}));



const Login = props => {

    const classes = useStyles();
    const history = useHistory();
    const {currentUser} = useContext(AuthContext);

    //REDIRECT LOGGEDIN USER TO HOME PAGE..........
    if(!!currentUser) history.push('/');

    const [form, setForm] = useState({
        email: '',
        password: ''
    });
    const [error, setError] = useState(null)

    //LOGIN USER HANDLER..........................
    const handleSubmit = () => {

        const { email, password } = form;

        auth.signInWithEmailAndPassword(email, password)
            .then(resp => {
                return resp.users;
            })
            .catch((error) =>{
                console.log(error);
 
                var errorCode = error.code;
                var errorMessage = error.message;

                if (errorCode == 'auth/wrong-password') {
                    setError('Wrong password. Please re-enter');
                    return;
                }
   
                setError(errorMessage);
            });
    }

    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justify="center"
                className={classes.root}
            >
                <Grid item xs={10} sm={4}>
                    <Card className={classes.card}>

                        <CardContent>
                            <TextField
                                required
                                id="email"
                                type="email"
                                name="email"
                                label="Email"
                                margin="normal"
                                fullWidth
                                onChange={(e) =>setForm({ ...form, email: e.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Mail />
                                        </InputAdornment>
                                    ),
                                }}
                            />

                            <TextField
                                required
                                id="password"
                                name="email"
                                label="Password"
                                type="password"
                                margin="normal"
                                fullWidth
                                onChange={(e) =>setForm({ ...form, password: e.target.value })}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Lock />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </CardContent>

                        <CardActions className={classes.action}>
                            <Button
                                id="btn_login"
                                color="primary"
                                className={classes.button}
                                variant="contained"
                                onClick={handleSubmit}>

                                <Send className={classes.extendedIcon} />
                                Login
                            </Button>
                        </CardActions>

                    </Card>

                    {error && <Alert severity="error" style={{ marginTop: 10 }}>{error}</Alert>}

                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default Login;
