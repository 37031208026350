import React from 'react';
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Grid from '@material-ui/core/Grid';
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  headline: Yup.string().required("Required"),
  publicationId: Yup.string().required("Required"),
  publishDate: Yup.string().required("Required"),
  journalistId: Yup.string().required("Required"),
  // storyFileFrom: Yup.string().required("Required"),
  // supplement: Yup.string().required("Required"),
  // magazineDate: Yup.string().required("Required"),
  clientId: Yup.string().required("Required")
});

const NewsClipForm = React.forwardRef((props, ref) =>{
  const {
    newsClipFormData, 
    publications,  
    journalists, 
    clients,
    submitHandler
  } = props;
  
  const {
    touched,
    errors,
    getFieldProps,
    setFieldValue,
    handleSubmit,
    values,
    resetForm
  } = useFormik({

    initialValues: { ...newsClipFormData },

    validationSchema,

    onSubmit: values => {
      submitHandler(values);
    }
  });

  const reporterList = journalists.filter(data => data.publicationId == values.publicationId)

  const getError= (field) =>{
    const errorStatus = {
        status: false,
        msg: ''
    }

    const hasError = touched[field] && errors[field] ? true : false;

    if(hasError){
        errorStatus.status = true;
        errorStatus.msg = errors[field];
    }

    return errorStatus;
  }

  return (
    <>
      {/* ---------------HEADLINE----------------- */}
      <Grid item sm={12}>
        <TextField
          autoFocus
          label="Headline"
          type="text"
          variant="outlined"
          fullWidth
          id="headline"
          {...getFieldProps("headline")}
          error={getError("headline").status}
          helperText={getError("headline").msg}
        />
      </Grid>

      {/* ---------------PUBLICATION----------------- */}
      <Grid item sm={6}>
        <Autocomplete
          disableClearable
          options={publications}
          getOptionLabel={(option) => option.name}
          value={publications.find(option => option.id == values.publicationId) || null}
          onChange={(event, newValue) => setFieldValue('publicationId', newValue.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Publication"
              variant="outlined"
              fullWidth
              error={getError("publicationId").status}
              helperText={getError("publicationId").msg}
            />
          )}
        />
      </Grid>

      {/* ---------------PUBLISH DATE----------------- */}
      <Grid item sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            inputVariant="outlined"
            openTo="date"
            format="dd/MM/yyyy"
            label={"Publish date"}
            id="publishDate"
            {...getFieldProps("publishDate")}
            onChange={date => setFieldValue('publishDate', date)}
            error={getError("publishDate").status}
            helperText={getError("publishDate").msg}
          />
        </MuiPickersUtilsProvider>
      </Grid>

      {/* ---------------REPORT TYPE----------------- */}
      <Grid item sm={6}>
        <Autocomplete
          disableClearable
          options={reporterList}
          getOptionLabel={(option) => option.name}
          value={reporterList.find(option => option.id == values.journalistId) || null}
          onChange={(event, newValue) => setFieldValue('journalistId', newValue.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Reporter"
              variant="outlined"
              fullWidth
              id="journalistId"
              error={getError("journalistId").status}
              helperText={getError("journalistId").msg}
            />
          )}
        />
      </Grid>

      {/* ---------------STORY FILE FROM----------------- */}
      <Grid item sm={6}>
        <TextField
          label="Story file from"
          type="text"
          variant="outlined"
          fullWidth
          id="storyFileFrom"
          {...getFieldProps("storyFileFrom")}
          error={getError("storyFileFrom").status}
          helperText={getError("storyFileFrom").msg}
        />
      </Grid>

      {/* ---------------SUPPLEMENT----------------- */}
      <Grid item sm={6}>
        <TextField
          label="Supplement"
          type="text"
          variant="outlined"
          fullWidth
          id="supplement"
          {...getFieldProps("supplement")}
          error={getError("supplement").status}
          helperText={getError("supplement").msg}
        />
      </Grid>

      {/* ---------------MAGAZINE DATE----------------- */}
      {/* <Grid item sm={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            inputVariant="outlined"
            openTo="date"
            format="dd/MM/yyyy"
            label={"Magazine Date"}
            id="magazineDate"
            {...getFieldProps("magazineDate")}
            onChange={date => setFieldValue('magazineDate', date)}
            error={getError("magazineDate").status}
            helperText={getError("magazineDate").msg}
          />
        </MuiPickersUtilsProvider>
      </Grid> */}

      {/* ---------------CLIENT----------------- */}
      <Grid item sm={6}>
        <Autocomplete
          disableClearable
          options={clients}
          getOptionLabel={(option) => option.name}
          value={clients.find(option => option.id == values.clientId) || null}
          onChange={(event, newValue) => setFieldValue('clientId', newValue.id)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Client"
              variant="outlined"
              fullWidth
              error={getError("clientId").status}
              helperText={getError("clientId").msg}
            />
          )}
        />
      </Grid>

      <button 
        type="button"
        name="step2" 
        ref={ref}
        onClick={handleSubmit} 
        hidden
      />
    </>
  )
});

export default NewsClipForm;