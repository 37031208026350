import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
    text: {
        display: "flex",
        fontSize: "11px",
        alignItems: "center",
        marginBottom: "10px",
        fontWeight: 600,
        justifyContent: "center",
        cursor: 'help'
    },
    icon: {
        fontSize: "20px",
        marginLeft: "5px",
    },
    tootip: {
        width: '1000px',
        padding: '10px',
        '& .positions':{
            paddingLeft: '10px',
            fontWeight: 600
        },
        '& p':{
            marginBottom: '10px'
        },
    }
}));

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      minWidth: '400px',
      fontSize: 12,
      border: '1px solid #dadde9',
    },
}))(Tooltip);
  

export default function HelperTooltip() {
    const classes = useStyles();

    return (
        <div>
            <HtmlTooltip 
                title={
                    <div className={classes.tootip}>
                        <p>Filename format: <b>keyword_position_pageNo_hasPhoto_photoType</b></p>
                        <p>example: <b>nature_tl_5_n_bw</b></p>
                        <p>keyword: <b>Text</b></p>
                        <p>position</p>
                        <div className="positions">
                            <p>tl: top-left</p>
                            <p>tr: top-right</p>
                            <p>tc: top-center</p>
                            <p>cl: center-left</p>
                            <p>c:  center</p>
                            <p>cr: center-right</p>
                            <p>bl: bottom-left</p>
                            <p>br: bottom-right</p>
                            <p>bc: bottom-center</p>
                        </div>

                        <p>pageNo: <b>number</b></p>
                        <p>hasPhoto:  <b>[ y: yes, n: no ]</b></p>
                        <p>photoType: <b>[ c: colored, bw: black and white, n: none ]</b></p>
                    </div>
                }
            >
                <div className={classes.text}>
                    Upload with specific file format to prepopulate input fields
                    <HelpOutlineIcon className={classes.icon} />
                </div>
            </HtmlTooltip>
        </div>
    );
}