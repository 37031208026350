import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from "./AuthProvider";
import { useHistory } from "react-router-dom";
import { ACCESS_DENIE_LIST } from "./contants/auth";

const hasAccessToPage = (currentUser, path) => {
    if(!currentUser) return false;

    const { role } = currentUser;

    if(ACCESS_DENIE_LIST?.[role]?.includes(path)) return false;

    return true;
}

const PrivateRoute = ({
    component: Component,
    layout: Layout,
    ...rest
}) => {

    const { currentUser } = useContext(AuthContext);
    const history = useHistory();
    const { pathname } = history.location;

    if(!currentUser){
        history.push('/login');
        return null;
    }

    if(!hasAccessToPage(currentUser, pathname)){
        history.push('/');
        return null;
    }

    return (
        <Route 
            {...rest}
            render={props => (
                <Layout>
                    <Component {...props} />
                </Layout>
            )}
        />
    );
};

export default PrivateRoute;