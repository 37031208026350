import React from "react";
import styled from "styled-components";
import LinkButton from "../Navigation/LinkButton";
import PageTitle from "./PageTitle";

const HeaderWrapper = styled.header`
  display: flex;
  align-items: center;
  padding: 2rem;

  & .header_page-title {
    flex: 1 1 0px;

    & > h1 {
      margin: 0;
    }
  }
`;

const Header = (props) => {
  return (
    <HeaderWrapper>
      <div className="header_page-title"></div>
      <div className="header_news-clip">
        <LinkButton to="/news-clips">+ News Clip</LinkButton>
      </div>
    </HeaderWrapper>
  );
};

export default Header;
