const theme = {
  colors: {
    main: "#4759e4",
    mainLight: "#f5f6f7",
    textColor: "#333",
    textGrey: "#c3c7cb",
    whiteColor: "#fff",
    border: "#e2e9ee",
  },

  fonts: {
    normal: "1rem",
    small: "0.9rem",
    title: "1.2rem",
    subTitle: "0.9rem",
  },

  mediaQueries: {
    smallest: `only screen and (max-width: 425px)`,
    small: `only screen and (max-width: 768px)`,
    medium: `only screen and (max-width: 1024px)`,
    large: `only screen and (max-width: 1440px)`,
    largest: `only screen and (max-width: 2560px)`,
  },
};

export default theme;
