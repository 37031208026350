import { auth, database } from '../../firebaseConfig';

export const getUserData = async(uid) =>{
    let userData = null;
    const response = await database.ref('users/' + uid).once("value", snap => {
        userData = snap.val();
    });
    return userData;
}

export const getCurrentUserInfo = async() =>{
    try {
        const userInfo = await auth.currentUser.getIdTokenResult();
        return userInfo.claims;

    } catch(error) {
        console.error(error);
        return;
    }
}