import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Layout from "./layout";
import Login from "./containers/Login";
import Dashboard from "./containers/Dashboard";
import NewsClips from "./containers/NewsClips";
import Users from "./containers/Users";
import Publications from "./containers/Publications";
import Journalists from "./containers/Journalists";

import PrivateRoute from './PrivateRoute';
import { AuthProvider } from "./AuthProvider";

const App = () => {
  return (
    <AuthProvider>
      <Switch>
        <Route exact path="/login" component={Login} />
        
        <PrivateRoute exact path="/" layout={Layout} component={Dashboard} />
        <PrivateRoute exact path="/news-clips" layout={Layout} component={NewsClips} />
        <PrivateRoute exact path="/users" layout={Layout} component={Users} />
        <PrivateRoute exact path="/publications" layout={Layout} component={Publications} />
        <PrivateRoute exact path="/journalists" layout={Layout} component={Journalists} />

        <Redirect to="/login" />

      </Switch>
    </AuthProvider>
  );
};

export default App;
