export const SIDEBAR_MENU = {
    dashboard: {
        id: 'dashboard',
        title: 'Dashboard',
        link: '/',        
    },
    newClips: {
        id: 'newClips',
        title: 'News clips',
        link: '/news-clips',        
    },
    users: {
        id: 'users',
        title: 'Users',
        link: '/users',        
    },
    publications: {
        id: 'publications',
        title: 'Publications',
        link: '/publications',        
    },
    journalists: {
        id: 'journalists',
        title: 'Journalists',
        link: '/journalists',        
    },
}