import React, { useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
    Grid,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormHelperText,
    Button
} from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ChipInput from "material-ui-chip-input";

import { useFormik } from "formik";
import * as Yup from "yup";

import UploadInput from './UploadInput';
import { POSITION_FORMAT, EXAMPLE_FORMAT } from '../../contants/newsClip';

const styles = theme => ({
    error: {
        color: '#f44336',
        marginLeft: '14px'
    },
    addBtnContianer: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        margin: "10px",
        '& .button': {
            width: "25%",
            margin: "0px 8px",
        }
    },
    formSeperator: {
        width: "100%",
        margin: "20px 10px",
        borderTop: "2px solid #3f51b5",
        borderRadius: "10px",
    }
});

const validationSchema = Yup.array().of(
    Yup.object().shape({
        position: Yup.string().required('required'),
        sqrCms: Yup.number().required('please upload an image'),
        pageNumber: Yup.number().required('required'),
        photoType: Yup.string().required('required'),
        hasPhoto: Yup.string().required('required'),
        keywords: Yup.array().min(1),
    })
);

const UploadImage = React.forwardRef((props, ref) => {

    const { 
        classes, 
        imageList, 
        submitHandler,
        initImageInfo
    } = props;

    const formik = useFormik({
        initialValues: [],

        validationSchema,

        onSubmit: values => {
            submitHandler(values);
        }
    });

    useEffect(() =>{
        formik.setValues([ ...imageList ]);
    }, [imageList]);

    const getError= (field, index) =>{
        const { touched, errors } = formik;
        const errorStatus = {
            status: false,
            msg: ''
        }

        if(touched[index] && errors[index]){
            const hasError = touched[index][field] && errors[index][field] ? true : false;

            if(hasError){
                errorStatus.status = true;
                errorStatus.msg = errors[index][field];
            }

            if(field == 'keywords') errorStatus.msg = 'Atleast one keyword is required';
        }
        return errorStatus;
    }

    const handleAddChip = (index, value) =>{
        const field = `${index}.keywords`;
        const existingValues = formik.values[index].keywords;
        const updatedValues = [ ...existingValues, value];
        formik.setFieldValue(field, updatedValues);

    }

    const handleDeleteChip = (index, chipIndex) =>{
        const { keywords } = formik.values[index];
        const field = `${index}.keywords`;
        const updatedValues = keywords.filter((chip, indx) => indx !== chipIndex);
        formik.setFieldValue(field, updatedValues);
    }

    const handleUploadSuccess = (index, imageData) =>{
        const imageSize = parseFloat(imageData.sqrCms.toFixed(2));

        //SET FORM FIELDS BASED ON FILENAME............................
        handleFilename(index, imageData)

        formik.setFieldValue(`${index}.url`, imageData.url);
        formik.setFieldValue(`${index}.sqrCms`, imageSize);
    }

    const handleFilename = (index, imageData) =>{
        let filename = imageData.filename;
        filename = filename.split('_');
        
        if(filename.length == 5){

            let [ keyword, position, pageNo, hasPhoto, photoType] = filename;

            handleAddChip(index, keyword);

            position = position.toLocaleLowerCase();
            if(Object.keys(POSITION_FORMAT).includes(position)){
                formik.setFieldValue(`${index}.position`, POSITION_FORMAT[position]);
            }

            formik.setFieldValue(`${index}.pageNumber`, pageNo);
            formik.setFieldValue(`${index}.hasPhoto`, hasPhoto.toLocaleLowerCase() == 'y' ? 'yes' : 'no');

            photoType = photoType.toLocaleLowerCase();
            let [ type, ext ] = photoType.split('.');
            type = type == 'c' ? 'colored' : type == 'bw' ? 'black and white' : 'none';
            formik.setFieldValue(`${index}.photoType`, type);
        }
    }

    const addNewImageForm = () =>{
        formik.setValues([ ...formik.values, initImageInfo ]);
    }
    
    const removeImageForm = () =>{
        const formData = formik.values;
        const lastImageIndex = formData.length - 1;
        const filteredFormData = formData.filter((d, index) => index != lastImageIndex);
        formik.setValues([ ...filteredFormData ]);
    }

    return (
        <>
            {formik.values.map((value, index) => (
                <React.Fragment key={index}>
                    
                    {index > 0 && <hr className={classes.formSeperator} />}

                    {/* -----------------UPLOAD IMAGE--------------------- */}
                    <UploadInput 
                        key={`upload_input_${index}`}
                        handleUploadSuccess={handleUploadSuccess} 
                        imageIndex={index}
                        imageUrl={value.url}
                    />

                    {/* -----------------IMAGE SQUARE CMS----------------- */}
                    <Grid item sm={6}>
                        <TextField
                            placeholder="Image square cms"
                            type="text"
                            variant="outlined"
                            disabled
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            {...formik.getFieldProps(`${index}.sqrCms`)}         
                            error={getError('sqrCms', index).status}
                            helperText={getError('sqrCms', index).msg}
                        />
                    </Grid>

                    {/* -----------------POSITION--------------------- */}
                    <Grid item sm={6}>
                        <TextField
                            label="Position"
                            type="text"
                            variant="outlined"
                            fullWidth
                            {...formik.getFieldProps(`${index}.position`)}
                            error={getError('position', index).status}
                            helperText={getError('position', index).msg}
                       />
                    </Grid>

                    {/* -----------------PAGE NUMBER--------------------- */}
                    <Grid item sm={6}>
                        <TextField
                            label="Page number"
                            type="number"
                            variant="outlined"
                            fullWidth
                            {...formik.getFieldProps(`${index}.pageNumber`)}
                            error={getError('pageNumber', index).status}
                            helperText={getError('pageNumber', index).msg}
                       />
                    </Grid>

                    {/* -----------------CLIP HAS PHOTO--------------------- */}
                    <Grid item sm={6}>
                        <FormControl required variant="outlined" fullWidth>
                            <InputLabel id="photo">Clip has photo</InputLabel>
                            <Select
                                labelId="photo"
                                label="Clip has photo"
                                id="photo"
                                {...formik.getFieldProps(`${index}.hasPhoto`)}
                                error={getError('hasPhoto', index).status}
                            >
                                <MenuItem value={"yes"}>Yes</MenuItem>
                                <MenuItem value={"no"}>No</MenuItem>
                            </Select>
                        </FormControl>

                        <FormHelperText className={classes.error}>{getError('hasPhoto', index).msg}</FormHelperText>
                    </Grid>

                    {/* -----------------CLIP PHOTO TYPE--------------------- */}
                    <Grid item sm={6}>
                        <FormControl required variant="outlined" fullWidth>
                            <InputLabel id="photoType">Clip photo type</InputLabel>
                            <Select
                                labelId="photoType"
                                label="Clip photo type"
                                id="photoType"
                                {...formik.getFieldProps(`${index}.photoType`)}
                                error={getError('photoType', index).status}
                            >
                                <MenuItem value={"none"}>None</MenuItem>
                                <MenuItem value={"colored"}>Colored</MenuItem>
                                <MenuItem value={"black and white"}>
                                    Black and white
                                </MenuItem>
                            </Select>
                        </FormControl>

                        <FormHelperText className={classes.error}>{getError('photoType', index).msg}</FormHelperText>
                    </Grid>

                    {/* -----------------KEYWORDS--------------------- */}
                    <Grid item sm={6}>
                        <ChipInput
                            label="Keywords"
                            fullWidth={true}
                            variant="outlined"
                            value={value.keywords}
                            onAdd={chip => handleAddChip(index, chip)}
                            onDelete={(chip, chipIndex) => handleDeleteChip(index, chipIndex)}
                            error={getError('keywords', index).status}
                            helperText={getError('keywords', index).msg}
                       />

                    </Grid>

                </React.Fragment>
            ))}

            {/* ---------------ADD NEW IMAGE FORM BUTTON---------------- */}
            
                <div className={classes.addBtnContianer}>

                    {formik.values.length < 3 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<AddIcon />}
                            className="button"
                            onClick={addNewImageForm}
                        >
                            Add
                        </Button>
                    )}

                    {formik.values.length > 1 && (
                        <Button
                            variant="outlined"
                            color="primary"
                            startIcon={<RemoveIcon />}
                            className="button"
                            onClick={removeImageForm}
                        >
                            Remove
                        </Button>
                    )}
                </div>
           
        
            <button 
                type="button"
                name="step1" 
                ref={ref}
                onClick={formik.handleSubmit} 
                hidden
            />
        </>
    )
});

export default withStyles(styles)(UploadImage);
