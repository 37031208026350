import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import firebase, { database } from '../../firebaseConfig';
import cloneDeep from 'lodash/cloneDeep';

import { AuthContext } from "../../AuthProvider";

import MaterialTable from 'material-table';
import AddPublicationDialog from './AddPublicationDialog';

import { getPublications } from "../../utils/helper";

const styles = theme =>({
    root: {
        margin: '0px 20px',
        '& .MuiToolbar-root > div:nth-of-type(4)': {
            order: -1
        }
    }
});

class Publications extends Component {

    static contextType = AuthContext;
    
    state = {
        showModal: false,
        publications: [],
        selectedPublication: null,
        selectedID: null
    }

    componentDidMount(){
        this.fetchPublications();
    }

    fetchPublications = () =>{
        getPublications()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const publications = Object.keys(datalist).map(id => ({ ...datalist[id], id }) );
                    this.setState({ publications });
                }
            });
    }

    addPublicationHandler = (form) =>{
        const { currentUser } = this.context;
        const publicationsListRef = database.ref('publications');
        const newPublicationsRef = publicationsListRef.push();

        newPublicationsRef.set({
            name: form.name,
            adValue: form.adValue,
            circulationValue: form.circulationValue,
            assignPublication: form.assignPublication,
            type: form.type,
            language: form.language,
            edition: form.edition,
            zone: form.zone,
            mentionsCount: 0,
            createdAt: firebase.database.ServerValue.TIMESTAMP,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            createdBy: currentUser.uid,
            updatedBy:  currentUser.uid,
            isActive: true
        });
        
        this.closeModalHandler();
    }

    updatePublicationHandler = (updateData) =>{

        const { selectedID } = this.state;
        const { currentUser } = this.context

        const publicationRef = database.ref('publications/' + selectedID);
        publicationRef.update({ 
            ...updateData,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            updatedBy:  currentUser.uid
        });
        this.closeModalHandler();
    }

    deletePublicationHandler = (id) =>{
        const { currentUser } = this.context;

        const publicationRef = database.ref('publications/' + id);
        publicationRef.update({ 
            isActive: false,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            updatedBy:  currentUser.uid  
        });
    }

    closeModalHandler = () =>{
        this.setState({ 
            showModal: false, 
            selectedID: null,
            selectedPublication: null
        });
    }

    editHandler = (event, rowData) =>{
        const filterKeys = [
            'name',
            'adValue',
            'circulationValue',
            'assignPublication',
            'type',
            'language',
            'edition',
            'zone',
            'id'
        ];

        const filteredData = Object.keys(rowData)
            .filter(key => filterKeys.includes(key))
            .reduce((res, key) =>Object.assign(res, { [key]: rowData[key]}), {});

        this.setState({
            selectedPublication: { ...filteredData },
            showModal: true,
            selectedID: rowData.id
        });
    }

    deleteHandler = (event, rowData) =>{
        const status = window.confirm("You want to delete " + rowData.type);
        if(status) this.deletePublicationHandler(rowData.id);
    }

    render(){
        const { classes } = this.props;
        const { 
            showModal, 
            publications, 
            selectedPublication, 
            selectedID 
        } = this.state;

        const tableData = cloneDeep(publications);

        return(
            <div className={classes.root}>
                <MaterialTable
                    title="Publications"
                    columns={[
                        { title: 'Name', field: 'name' },
                        { title: 'Ad Value', field: 'adValue' },
                        { title: 'Circulation Value', field: 'circulationValue' },
                        { title: 'Assign Publication', field: 'assignPublication'},
                        { title: 'Type', field: 'type'},
                        { title: 'Language', field: 'language'},
                        { title: 'Zone', field: 'zone'},
                        { title: 'Edition', field: 'edition'},
                    ]}
                    data={tableData}        
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add Publication',
                            isFreeAction: true,
                            onClick: (event) => this.setState({ showModal: true })
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Update Publication',
                            onClick: this.editHandler
                        },
                        rowData => ({
                            icon: 'delete',
                            tooltip: 'Delete Publication',
                            onClick: this.deleteHandler,
                            disabled: rowData.birthYear < 2000
                        })
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: []
                    }}
                />

                {showModal && (
                    <AddPublicationDialog 
                        open={showModal}
                        addPublicationHandler={this.addPublicationHandler}
                        updatePublicationHandler={this.updatePublicationHandler}
                        handleClose={this.closeModalHandler}
                        selectedPublication={selectedPublication}
                        selectedID={selectedID}
                    />
                )}
                
            </div>
        )
    }
}

export default withStyles(styles)(Publications);
