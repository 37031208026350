import { database } from '../firebaseConfig';
import { ROLES } from "../contants/auth";

export const getPublications = () =>{
    return database.ref('publications')
        .orderByChild('isActive')
        .equalTo(true)
}

export const getJournalists = () =>{
    return database.ref('journalists')
        .orderByChild('isActive')
        .equalTo(true)
} 

export const getClients = () =>{
    return database.ref('users')
        .orderByChild('role')
        .equalTo(ROLES.client)
} 

export const getNewsClips = () =>{
    return database.ref('newsClips')
        .orderByChild('isActive')
        .equalTo(true)
} 

export const getUsers = () =>{
    return database.ref('users')
        .orderByChild('isActive')
        .equalTo(true)
}

//Dashboard stats....................
export const fetchClipsbyDateRange = (startDate, endDate) =>{
    return database.ref('newsClips')
        .orderByChild("createdAt")
        .startAt(startDate)
        .endAt(endDate)
        .once('value')
}

export const getLatestNewsClips = () =>{
    return database.ref('newsClips')
        .orderByChild("createdAt")
        .limitToLast(20)
        .once('value')
}

export const getPubByMentions = () =>{
    return database.ref('publications')
        .orderByChild("mentionsCount")
        .limitToLast(20)
        .once('value')
}

export const getJournalistsByMentions = () =>{
    return database.ref('journalists')
        .orderByChild("mentionsCount")
        .limitToLast(20)
        .once('value')
}