import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PropTypes from "prop-types";

import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

const initialValues = {
    email: '',
    name: '',
    phone: '',
    publicationId: '',
    category: ''
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    phone: Yup.string().required("Required"),
    category: Yup.string().required("Required"),
    publicationId: Yup.string().required("Required"),
});

const AddJournalistsDialog = (props) => {

    const {
        addJournalistHandler,
        updateJournalistHandler,
        open,
        handleClose,
        selectedJournalist,
        selectedID,
        publications
    } = props;

    useEffect(() => {
        if (selectedID) setValues({ ...selectedJournalist });
    }, [selectedID]);

    const {
        touched,
        errors,
        getFieldProps,
        setValues,
        setFieldValue,
        handleSubmit,
        values
    } = useFormik({

        initialValues: { ...initialValues, ...selectedJournalist },

        validationSchema,

        onSubmit: values => {

            if (selectedID) {
                updateJournalistHandler(values);
                return;
            }

            addJournalistHandler(values);
        }
    });

    const getError = (field) => {
        const errorStatus = {
            status: false,
            msg: ''
        }

        const hasError = touched[field] && errors[field] ? true : false;

        if (hasError) {
            errorStatus.status = true;
            errorStatus.msg = errors[field];
        }

        return errorStatus;
    }

    return (
        <Dialog
            open={open}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Add Journalist</DialogTitle>
            
            <DialogContent>

                {/* -------------NAME INPUT---------------- */}
                <TextField
                    error={getError("name").status}
                    helperText={getError("name").msg}
                    autoFocus
                    margin="dense"
                    label="Name"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="name"
                    {...getFieldProps("name")}
                />

                {/* -------------EMAIL INPUT---------------- */}
                <TextField
                    error={getError("email").status}
                    helperText={getError("email").msg}
                    margin="dense"
                    label="Email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    id="email"
                    {...getFieldProps("email")}
                />

                {/* -------------PHONE INPUT---------------- */}
                <TextField
                    error={getError("phone").status}
                    helperText={getError("phone").msg}
                    margin="dense"
                    label="Phone"
                    type="tel"
                    variant="outlined"
                    fullWidth
                    id="phone"
                    {...getFieldProps("phone")}
                />

                {/* -------------PUBLICATION INPUT---------------- */}
                <Autocomplete
                    disableClearable
                    options={publications}
                    getOptionLabel={(option) => option.name}
                    value={publications.find(option => option.id == values.publicationId) || null}
                    onChange={(event, newValue) => setFieldValue('publicationId', newValue.id)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Publication"
                            variant="outlined"
                            fullWidth
                            error={getError("publicationId").status}
                            helperText={getError("publicationId").msg}
                        />
                    )}
                />

                {/* -------------CATEGORY INPUT---------------- */}
                <TextField
                    error={getError("category").status}
                    helperText={getError("category").msg}
                    margin="dense"
                    label="Catergory"
                    type="text"
                    variant="outlined"
                    fullWidth
                    id="category"
                    {...getFieldProps("category")}
                />

            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>

                <Button onClick={handleSubmit} color="primary">
                    {selectedID ? 'Update' : 'Add' }
                </Button>
            </DialogActions>
        </Dialog>
    );
};

AddJournalistsDialog.propTypes = {
    open: PropTypes.bool, 
    handleClose: PropTypes.func.isRequired,
    addJournalistHandler: PropTypes.func.isRequired,
};

export default AddJournalistsDialog;
