import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import firebase, { database, functions } from '../../firebaseConfig';
import cloneDeep from 'lodash/cloneDeep';
import Snackbar from "@material-ui/core/Snackbar";

import MaterialTable from 'material-table';
import AddUserDialog from "./AddUserDialog";
import { AuthContext } from "../../AuthProvider";

import { getUsers } from "../../utils/helper";

const styles = theme =>({
    root: {
        margin: '0px 20px',
        '& .MuiToolbar-root > div:nth-of-type(4)': {
            order: -1
        }
    }
});

class Users extends Component{

    static contextType = AuthContext;

    state = {
        showModal: false,
        users: [],
        selectedUser: null,
        selectedID: null,
        error: ''
    }

    componentDidMount(){
        this.fetchUsers();
    }

    fetchUsers = () =>{
        const { currentUser } = this.context;
        getUsers()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const users = Object.keys(datalist)
                    .filter(id => id !== currentUser.uid)
                    .map(id => ({ ...datalist[id], id }));
                    this.setState({ users });
                }
            });
    }

    addUserHandler = (form) =>{
        const { showLoader, hideLoader } = this.context;
        const createUser = functions.httpsCallable('createUser');

        const userData = {
            username: form.username,
            email: form.email,
            password: form.password,
            role: form.role,
            createdAt: firebase.database.ServerValue.TIMESTAMP,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
        };

        showLoader();

        createUser(userData)
            .then((resp) => {
                //Display success
                console.log(resp.data.result);
                hideLoader();
            })
            .catch((error) => {
                console.log(error);
                const code = error.code;
                const message = error.message;
                this.setState({ error: message });
                hideLoader();
                //Display error
            });
                
        this.closeModalHandler();
    }

    editHandler = (event, rowData) =>{
        const filterKeys = [
            'username',
            'email',
            'role'
        ];

        const filteredData = Object.keys(rowData)
            .filter(key => filterKeys.includes(key))
            .reduce((res, key) =>Object.assign(res, { [key]: rowData[key]}), {});

        this.setState({
            selectedUser: { ...filteredData },
            showModal: true,
            selectedID: rowData.id
        });
    }

    updateUserHandler = (form) =>{

        const { showLoader, hideLoader } = this.context;
        const { selectedID } = this.state;

        const updateUser = functions.httpsCallable('updateUser');

        const userData = {
            uid: selectedID,
            username: form.username,
            email: form.email,
            password: form.password,
            role: form.role,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
        };

        showLoader();

        updateUser(userData)
            .then((resp) => {
                //Display success
                console.log(resp.data.result);
                hideLoader();
            })
            .catch((error) => {
                console.log(error);
                const code = error.code;
                const message = error.message;
                this.setState({ error: message });
                hideLoader();
                //Display error
            });
                
        this.closeModalHandler();
    }

    deleteHandler = (event, rowData) =>{
        const status = window.confirm("You want to delete " + rowData.username);
        if(status) this.deleteUserHandler(rowData.id, rowData.role);
    }

    deleteUserHandler = (id, role) =>{
        const { showLoader, hideLoader } = this.context;
        const deleteUser = functions.httpsCallable('deleteUser');

        const userData = {
            uid: id,
            role,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
        };

        showLoader();
        
        deleteUser(userData)
            .then((resp) => {
                //Display success
                console.log(resp.data.result);
                hideLoader();
            })
            .catch((error) => {
                console.log(error);
                const code = error.code;
                const message = error.message;
                this.setState({ error: message });
                hideLoader();
                //Display error
            });
    }

    closeModalHandler = () =>{
        this.setState({ 
            showModal: false, 
            selectedID: null,
            selectedUser: null
        });
    }

    handleClose = () =>{
        this.setState({ error: '' });
    }
    
    render(){
        const { classes } = this.props;
        const { 
            showModal, 
            users, 
            selectedUser, 
            selectedID,
            error
        } = this.state;

        const tableData = cloneDeep(users);

        return(
            <div className={classes.root}>
                <MaterialTable
                    title="Users"
                    columns={[
                        { title: 'Name', field: 'username' },
                        { title: 'Email', field: 'email' },
                        { title: 'User Type', field: 'role' },
                    ]}
                    data={tableData}        
                    actions={[
                        {
                            icon: 'add',
                            tooltip: 'Add User',
                            isFreeAction: true,
                            onClick: (event) => this.setState({ showModal: true })
                        },
                        {
                            icon: 'edit',
                            tooltip: 'Update User',
                            onClick: this.editHandler
                        },
                        rowData => ({
                            icon: 'delete',
                            tooltip: 'Delete User',
                            onClick: this.deleteHandler
                        })
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: []
                    }}
                />

                {showModal && (
                    <AddUserDialog 
                        open={showModal}
                        addUserHandler={this.addUserHandler}
                        updateUserHandler={this.updateUserHandler}
                        handleClose={this.closeModalHandler}
                        selectedUser={selectedUser}
                        selectedID={selectedID}
                    />
                )}

                {error && (
                    <Snackbar
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={true}
                        autoHideDuration={6000}
                        onClose={this.handleClose}
                        message={error}
                    />
                )}
                
            </div>
        )
    }
}

export default withStyles(styles)(Users);
