const ROLES = {
    super_admin: 'super_admin',
    admin: 'admin',
    client: 'client'
}

const ACCESS_DENIE_LIST = {
    [ROLES.super_admin]: [],
    [ROLES.admin]: ['/users'],
    [ROLES.client]: ['/users', '/publications', '/journalists'],
}

export { ROLES, ACCESS_DENIE_LIST };