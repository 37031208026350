
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firebase-storage';
import 'firebase/functions';

const firebaseConfig = {
    apiKey: "AIzaSyB4dMcm8MhXhtFoX7CRFzKTUa26Ex7zRKg",
    authDomain: "fifteen73media-tracker.firebaseapp.com",
    databaseURL: "https://fifteen73media-tracker-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "fifteen73media-tracker",
    storageBucket: "fifteen73media-tracker.appspot.com",
    messagingSenderId: "29755568709",
    appId: "1:29755568709:web:cc611dc7ba7dfe7f5ee92c",
    measurementId: "G-EK0PFWTNPP"
};

//TEST CONFIG..................................
// const firebaseConfig = {
//     apiKey: "AIzaSyAQNmPgpmCVgwXtFqkgzmNtwduYuo13iuU",
//     authDomain: "fifteen73media.firebaseapp.com",
//     databaseURL: "http://localhost:9000/?ns=fir-test-b04ef",
//     projectId: "fir-test-b04ef",
//     storageBucket: "gs://default-bucket/"
// };

app.initializeApp(firebaseConfig);
const database = app.database();
const auth = app.auth();
const storage = app.storage();
const functions = app.functions();

// EMULATOR SETTINGS................................
// if(window.location.hostname === 'localhost'){
//     database.useEmulator('localhost', 9000);
//     auth.useEmulator('http://localhost:9099/', { disableWarnings: true });
//     storage.useEmulator("localhost", 9199);
//     functions.useEmulator('localhost', 5001);
// }

export default app;
export { auth, database, storage, functions };