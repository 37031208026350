import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import firebase, { database } from '../../firebaseConfig';
import cloneDeep from 'lodash/cloneDeep';
import moment from "moment";

import MaterialTable from 'material-table';
import AddNewsClipDialog from './AddNewsClipsDialog';

import { AuthContext } from "../../AuthProvider";
import { 
    getPublications, 
    getJournalists,
    getClients,
    getNewsClips
} from "../../utils/helper";
import { ROLES } from "../../contants/auth";

const styles = theme =>({
    root: {
        margin: '0px 20px',
        '& .MuiToolbar-root > div:nth-of-type(4)': {
            order: -1
        }
    }
});

const formatDateDisplay = (timeStamp) =>{
    const formatedDate = moment(timeStamp).format('DD-MM-YYYY');
    return formatedDate;
}

const fetchKeywords = (imageData) =>{   
    let keywords = [];
    imageData.forEach(image =>{
        keywords = [ ...keywords, image.keywords ];
    });
    keywords = keywords.join(', ');
    return keywords;
}

class NewsClips extends Component {

    static contextType = AuthContext;

    state = {
        showModal: false,
        journalists: [],
        selectedNewsClip: null,
        selectedID: null,
        publications: [],
        newsClips: [],
        clients: []
    }

    componentDidMount(){
        this.fetchPublications();
        this.fetchJournalists();
        this.fetchNewsClips();
        this.fetchClients();
    }

    fetchNewsClips = () =>{
        
        const { currentUser } = this.context;

        getNewsClips()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    let newsClips = Object.keys(datalist).map(id => ({ ...datalist[id], id }));

                    if(currentUser.role == ROLES.client){
                        newsClips = newsClips.filter(data => data.clientId == currentUser.uid);
                    }

                    this.setState({ newsClips });
                }
            });
    }

    fetchPublications = () =>{
        getPublications()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const publications = Object.keys(datalist).map(id => ({ 
                        id, 
                        name: datalist[id].name, 
                        mentionsCount: datalist[id].mentionsCount
                    }));
                    this.setState({ publications });
                }
            });
    }

    fetchJournalists = () =>{
        getJournalists()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const journalists = Object.keys(datalist).map(id => ({ 
                        id,
                        name: datalist[id].name,  
                        publicationId: datalist[id].publicationId,
                        mentionsCount: datalist[id].mentionsCount 
                    }));
                    this.setState({ journalists });
                }
            });
    }

    fetchClients = () =>{
        getClients()
            .on('value', (snapshot) => {
                const datalist = snapshot.val();
                if(datalist){
                    const clients = Object.keys(datalist)
                    .filter(id => datalist[id].isActive)
                    .map(id => ({ id, name: datalist[id].username }));
                    this.setState({ clients });
                }
            });
    }

    addNewClipHandler = (form, resetForm) =>{
        const { currentUser } = this.context;

        const newsClipsRef = database.ref('newsClips');
        const newNewsClipsRef = newsClipsRef.push();

        newNewsClipsRef.set({
            ...form,
            publishDate: new Date(form.publishDate).getTime(),
            // magazineDate: new Date(form.magazineDate).getTime(),
            createdAt: firebase.database.ServerValue.TIMESTAMP,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            createdBy: currentUser.uid,
            updatedBy:  currentUser.uid,
            isActive: true
        });
        // resetForm();
        this.updateMentionsCount(form.journalistId);
        this.closeModalHandler();
        
    }

    updateMentionsCount = (reporterId) =>{
        const { journalists, selectedID, selectedNewsClip } = this.state;
        const selectedJournalist = journalists.find(data => data.id == reporterId);
        const publicationId = selectedJournalist.publicationId;
        const journalistId = selectedJournalist.id;

        const publicationRef = database.ref('publications/');
        const journalistsRef = database.ref('journalists/');

        //UPDATE MENTIONS COUNT ON UPDATE NEWS CLIP.............................
        if(selectedID){

            //CHECK IF JOURNALIST IS UPDATED AND UPDATED PREVIOUS AND NEW JOURNALISTS MENTIONS COUNT...
            if(journalistId !== selectedNewsClip.journalistId){

                journalistsRef.child(`${selectedNewsClip.journalistId}/mentionsCount`)
                .transaction((currentValue) => currentValue > 1 ? currentValue - 1 : 0);

                journalistsRef.child(`${journalistId}/mentionsCount`)
                .transaction((currentValue) => currentValue + 1);
            }

            //CHECK IF PUBLICATION IS UPDATED AND UPDATED PREVIOUS AND NEW PUBLICATIONS MENTIONS COUNT..
            if(publicationId !== selectedNewsClip.publicationId){

                publicationRef.child(`${selectedNewsClip.publicationId}/mentionsCount`)
                .transaction((currentValue) => currentValue > 1 ? currentValue - 1 : 0);

                publicationRef.child(`${publicationId}/mentionsCount`)
                .transaction((currentValue) => currentValue + 1);
            }

            return;
        }
        
        //INCREMENT MENTIONS COUNT ON CREATE NEW CLIP....................
        journalistsRef.child(`${journalistId}/mentionsCount`)
        .transaction((currentValue) => currentValue + 1);

        publicationRef.child(`${publicationId}/mentionsCount`)
        .transaction((currentValue) => currentValue + 1);
        
    }

    updateNewsClipHandler = (form, resetForm) =>{

        const { selectedID } = this.state;
        const { currentUser } = this.context;

        const newsClipsRef = database.ref('newsClips/' + selectedID);
        newsClipsRef.update({ 
            ...form,
            publishDate: new Date(form.publishDate).getTime(),
            // magazineDate: new Date(form.magazineDate).getTime(),
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            updatedBy:  currentUser.uid
        });
        this.updateMentionsCount(form.journalistId);
        this.closeModalHandler();
    }

    deleteNewsClipHandler = (id) =>{
        const { newsClips } = this.state;
        const { currentUser } = this.context;

        const newsClipsRef = database.ref('newsClips/' + id);
        newsClipsRef.update({ 
            isActive: false,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
            updatedBy:  currentUser.uid 
        });

        const publicationRef = database.ref('publications/');
        const journalistsRef = database.ref('journalists/');

        const { journalistId, publicationId } = newsClips.find(clip => clip.id == id);

        //UPDATE MENTIONS COUNT ON DELETE NEWS CLIP.............................
        journalistsRef.child(`${journalistId}/mentionsCount`)
        .transaction((currentValue) => currentValue > 1 ? currentValue - 1 : 0);

        publicationRef.child(`${publicationId}/mentionsCount`)
        .transaction((currentValue) => currentValue > 1 ? currentValue - 1 : 0);
    }

    closeModalHandler = () =>{
        this.setState({ 
            showModal: false, 
            selectedID: null,
            selectedNewsClip: null
        });
    }

    editHandler = (event, rowData) =>{

        const { tableData, ...filteredData } = rowData;

        this.setState({
            selectedNewsClip: { ...filteredData },
            showModal: true,
            selectedID: rowData.id
        });
    }

    deleteHandler = (event, rowData) =>{
        const status = window.confirm("You want to delete " + rowData.type);
        if(status) this.deleteNewsClipHandler(rowData.id);
    }

    getActionsList = () =>{
        const { currentUser } = this.context;

        if(currentUser.role != ROLES.client){
            return [
                {
                    icon: 'add',
                    tooltip: 'Add News Clip',
                    isFreeAction: true,
                    onClick: (event) => this.setState({ showModal: true })
                },
                {
                    icon: 'edit',
                    tooltip: 'Update News Clip',
                    onClick: this.editHandler
                },
                rowData => ({
                    icon: 'delete',
                    tooltip: 'Delete News Clip',
                    onClick: this.deleteHandler,
                    // disabled: rowData.birthYear < 2000
                })
            ]
        }

        return [];
    }

    render(){
        const { classes } = this.props;
        const { 
            showModal, 
            journalists, 
            selectedNewsClip, 
            selectedID, 
            publications,
            newsClips,
            clients
        } = this.state;

        const tableData = cloneDeep(newsClips);

        return(
            <div className={classes.root}>
                <MaterialTable
                    title="News Clips"
                    columns={[
                        { title: 'Headline', field: 'headline' },
                        { 
                            title: 'Publication', 
                            field: 'publicationId', 
                            render: rowData =>  publications.find(item => item.id == rowData.publicationId)?.name
                        },
                        { 
                            title: 'Publish date', 
                            field: 'publishDate', 
                            render: rowData => formatDateDisplay(rowData.publishDate)
                        },
                        { 
                            title: 'Reporter', 
                            field: 'journalistId', 
                            render: rowData =>  journalists.find(item => item.id == rowData.journalistId)?.name
                        },
                        { title: 'Story file from', field: 'storyFileFrom'},
                        { title: 'Supplement', field: 'supplement'},
                        // { 
                        //     title: 'Magzine date', 
                        //     field: 'magazineDate',
                        //     render: rowData => formatDateDisplay(rowData.magazineDate)
                        // },
                        { 
                            title: 'Keywords', 
                            field: 'keywords',
                            render: rowData => fetchKeywords(rowData.images)
                        },

                    ]}
                    data={tableData}        
                    actions={this.getActionsList()}
                    options={{
                        actionsColumnIndex: -1,
                        pageSize: 15,
                        pageSizeOptions: []
                    }}
                />

                {showModal && (
                    <AddNewsClipDialog 
                        open={showModal}
                        addNewClipHandler={this.addNewClipHandler}
                        updateNewsClipHandler={this.updateNewsClipHandler}
                        handleClose={this.closeModalHandler}
                        selectedNewsClip={selectedNewsClip}
                        selectedID={selectedID}
                        publications={publications}
                        journalists={journalists}
                        clients={clients}
                    />
                )}
            
            </div>
        )
    }
}

export default withStyles(styles)(NewsClips);

