import React from "react";
import styled from "styled-components";

import User from "../../User";
import NavItems from "../NavItems";

const AsideWrapper = styled.aside`
  background-color: var(--color-main);
  padding: 0rem 2rem;
  width: 15rem;

  @media ${(props) => props.theme.mediaQueries.small} {
    display: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-content: flex-start;
`;

const Navbar = () => {
  return (
    <AsideWrapper>
      <Wrapper>
        <User />
        <NavItems />
      </Wrapper>
    </AsideWrapper>
  );
};

export default Navbar;
